import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './hero.css';

const Hero = ({ children, as = 'div', size, path }) => {
  return (
    <Wrapper
      as={as}
      size={size}
      path={path}
      // style={{
      //   width: 100%,
      // }}
    >
      {children}
    </Wrapper>
  );
};

Hero.propTypes = {
  children: PropTypes.string.isRequired,
  as: PropTypes.string,
  size: PropTypes.oneOf(['large']),
  path: PropTypes.string,
};

export default Hero;
