import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import bgImage from './hero.jpg';

export const Wrapper = styled.div`
  display: block;
  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: inherit;
  z-index: 20;

  font-weight: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '500';
      case 'small':
        return '400';
      default:
        return '500';
    }
  }};
  font-size: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '3.2rem';
      case 'small':
        return '1.3rem';
      default:
        return '2rem';
    }
  }};
  line-height: 1.2;

  ${MEDIA.TABLET`
    font-size: ${({ size }) => () => {
      switch (size) {
        case 'large':
          return '2.6rem';
        case 'small':
          return '1.3rem';
        default:
          return '2rem';
      }
    }};
  `};
`;
